import {reactive} from 'vue'
import VueJwtDecode from 'vue-jwt-decode';
import {refreshToken} from "@/api/user";
import router from "@/router";
import {user} from "@/data/user";

export const auth = reactive({
    access_token: '',
    refresh_token: '',
    user_id: '',

    setToken(access_token, refresh_token) {
        this.access_token = access_token
        this.refresh_token = refresh_token
        this.user_id = VueJwtDecode.decode(this.access_token)['sub']

        localStorage.setItem('access_token', this.access_token)
        localStorage.setItem('refresh_token', this.refresh_token)
        localStorage.setItem('user_id', this.user_id)
    },

    async getAccessToken() {
        if (!this.access_token && localStorage.getItem('access_token')) {
            this.access_token = localStorage.getItem('access_token')
        }
        if(!this.access_token){
            await this.logout()
        }
        if (isTokenExpired(this.access_token)) {
            await this.updateAccessToken()
        }
        return this.access_token
    },

    getRefreshToken() {
        if (!this.refresh_token && localStorage.getItem('refresh_token')) {
            this.refresh_token = localStorage.getItem('refresh_token')
        }
        return this.refresh_token
    },

    async updateAccessToken() {
        try {
            const result = await refreshToken()
            this.access_token = result.data.access_token
            localStorage.setItem('access_token', this.access_token)
        } catch (error) {
            await this.logout()
        }
    },
    async logout() {
        this.access_token = ''
        this.refresh_token = ''
        this.user_id = ''

        await user.afterLogout()

        localStorage.setItem('access_token', '')
        localStorage.setItem('refresh_token', '')
        localStorage.setItem('user_id', '')

        await router.push({name: 'login'})
    },

    checkAuth() {
        return !!this.getAccessToken();
    }
})

function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

function isTokenExpired(token) {
    const decoded = parseJwt(token);
    const exp = decoded.exp;
    const currentTime = Math.floor(Date.now() / 1000);
    return exp < currentTime; // Return true if token is expired
}