<template>
  <LoadingBlock :loading="fileLoading">
    <form @submit.prevent="importSubmit">
      <div class="col mb-4">
        <p v-if="type === 'costs'">
          {{ update_costs.text }}
        </p>
        <p v-if="type === 'minPrices'">
          {{ update_min_prices.text }}
        </p>
      </div>
      <div class="col mb-4">
        <label class="form-label">Загрузите файл</label>
        <input type="file" name="file" class="form-control" placeholder="" required @change="onFileChange">
      </div>
      <div class="col mb-4 text-center">
        <input type="submit" class="btn btn-primary" value="Отправить">
      </div>
    </form>
  </LoadingBlock>
</template>
<script>
import {user} from "@/data/user";
import {uploadCosts, uploadMinPrices} from "@/api/product";
import {Message, Messages} from "@/data/messages";
import LoadingBlock from "@/components/page/LoadingBlock.vue";


export default {
  name: "ProductImportFileForm",
  components: {LoadingBlock},
  data() {
    return {
      update_costs: {
        'text': 'Загрузите Excel файл с 2 колонками. Первую назовите "код товара", вторую - "себестоимость"',
      },
      update_min_prices: {
        'text': 'Загрузите Excel файл с 2 колонками. Первую назовите "код товара", вторую - "ррц"',
      },
      file: null,
      type: null,
      fileLoading: false
    }
  },
  methods: {
    onFileChange(event) {
      this.file = event.target.files[0];
    },
    async importSubmit() {
      const company = await user.getActiveCompany()
      this.fileLoading = true
      let result
      let text
      if (this.type === 'costs') {
        result = await uploadCosts(company, this.file)
        text = 'Себестоимость товаров успешно обновлена'
      } else if (this.type === 'minPrices') {
        result = await uploadMinPrices(company, this.file)
        text = 'РРЦ товаров успешно обновлена'
      }
      const message = new Message()
      if (result.status === 200) {
        message.type = 'success'
        message.autoremove = 10
        message.text = text
        this.$emit('fileSuccess')
      } else {
        message.type = 'failure'
        message.text = 'При обновлении цен произвошла ошибка. Проверьте файл, попробуйте снова или обратитесь в поддержку.'
      }
      Messages.list.push(message)
      this.fileLoading = false
    },
    setImport(type) {
      if (type === 'minPrices') {
        this.type = 'minPrices'
      }
      if (type === 'costs') {
        this.type = 'costs'
      }
    }
  },
}
</script>