import { reactive } from 'vue'

export class Message{
    constructor(title='', text='', type='success', format='notification', autoremove=0) {
        this.title = title
        this.text = text
        this.type = type
        this.format = format
        this.autoremove = autoremove
        this.id = this.generateId()
    }
    generateId() {
      return `item-${Date.now()}-${Math.floor(Math.random() * 1000)}`;
    }
}

export const Messages = reactive({
    list: []
})