<template>
  <nav
      class="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
      id="layout-navbar">
    <div class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
      <a class="nav-item nav-link px-0 me-xl-4" @click.prevent="MobileMenu.toggle()" href="javascript:void(0);">
        <i class="ti ti-menu-2 ti-md"></i>
      </a>
    </div>

    <div class="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
      <ul class="navbar-nav flex-row align-items-center ms-auto">
        <li>
          <Select v-model="activeCompany" :options="userCompanies"
                  optionLabel="name" optionValue="id"
                  style="margin-right: 10px"
                  @change="setActiveCompany"/>
        </li>
        <!-- User -->
        <li class="nav-item navbar-dropdown dropdown-user dropdown" v-click-outside="closeUserMenu">
          <a
              class="nav-link dropdown-toggle hide-arrow p-0"
              href="javascript:void(0);"
              data-bs-toggle="dropdown" @click="showUserMenu = !showUserMenu">
            <div class="avatar">
              <i class="ti ti-user ti-xl"></i>
            </div>
          </a>
          <ul class="dropdown-menu dropdown-menu-end" data-bs-popper="static" :class="showUserMenu ? 'show' : ''">
            <li>
              <a class="dropdown-item mt-0" href="#">
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1">
                    <h6 class="mb-0">{{ user_info.name }}</h6>
                    <small class="text-muted"></small>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <div class="dropdown-divider my-1 mx-n2"></div>
            </li>
            <li>
              <a class="dropdown-item" href="#">
                <i class="ti ti-user me-3 ti-md"></i><span class="align-middle">Профиль</span>
              </a>
            </li>
            <li>
              <a class="dropdown-item" href="#">
                <i class="ti ti-settings me-3 ti-md"></i><span class="align-middle">Компания</span>
              </a>
            </li>
            <li>
              <div class="dropdown-divider my-1 mx-n2"></div>
            </li>
            <li>
              <div class="d-grid px-2 pt-2 pb-1">
                <a class="btn btn-sm btn-danger d-flex" href="javascript:void(0);" @click="logout">
                  <small class="align-middle">Выйти</small>
                  <i class="ti ti-logout ms-2 ti-14px"></i>
                </a>
              </div>
            </li>
          </ul>
        </li>
        <!--/ User -->
      </ul>
    </div>
  </nav>
</template>

<script>
import {MobileMenu} from "@/data/menu";
import {VOnClickOutside as ClickOutside} from "@/helpers/directives/clickOutside"
import {user} from "@/data/user";
import {Select} from "primevue";
import {auth} from "@/data/auth";

export default {
  name: "NavBar",
  components: {Select},
  computed: {
    MobileMenu() {
      return MobileMenu
    },
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      showUserMenu: false,
      user_info: {},
      activeCompany: '',
      userCompanies: []
    }
  },
  methods: {
    closeUserMenu() {
      this.showUserMenu = false
    },
    async getUserInfo() {
      this.user_info = await user.getUserBase()
    },
    async logout() {
      await auth.logout()
    },
    setActiveCompany() {
      user.setActiveCompany(this.activeCompany)
      setTimeout(() => location = window.location, 200)
    }
  },
  async mounted() {
    setTimeout(this.getUserInfo, 1000)
    this.userCompanies = await user.getUserCompanies()
    this.activeCompany = await user.getActiveCompany()
  }
}
</script>
<style>
.layout-navbar, .layout-navbar-fixed body:not(.modal-open) .layout-content-navbar .layout-navbar, .layout-menu-fixed body:not(.modal-open) .layout-content-navbar .layout-navbar, .layout-menu-fixed-offcanvas body:not(.modal-open) .layout-content-navbar .layout-navbar {
  z-index: 20
}
</style>