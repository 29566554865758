<template>
  <button type="button"  class="loading-button" :class="this.class" :disabled="loading">
    <slot></slot>
    <template v-if="loading">
      <span class="spinner-grow" role="status" aria-hidden="true"></span>
    </template>
  </button>
</template>
<script>

export default {
  name: 'ButtonComponent',
  data() {
    return {
      loading: false
    }
  },
  props: {
    class: {
      type: String,
      defaultValue: 'btn'
    }
  },
  methods: {
    startLoading(){
      this.loading = true
    },
    endLoading(){
      this.loading = false
    }
  },
}
</script>

<style>
.loading-button{
  position: relative;
}
.loading-button .spinner-border, .loading-button .spinner-grow{
  position: absolute;
  top: auto;
  left: auto;
  bottom: auto;
  right: auto;
}
</style>