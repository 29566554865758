import axios from "axios";
import {API_URL} from "@/api/base";
import {auth} from "@/data/auth";


export async function getReportV1(searchData) {
    searchData = makeSearchData(searchData)
    return await axios.post(
        `${API_URL}/v1/order/report_v1`,
        searchData,
        {headers: {'Authorization': `Bearer ${await auth.getAccessToken()}`}}
    )
}


export async function getOrdersCalculated(searchData, page, size) {
    searchData = makeSearchData(searchData)
    searchData.page = page
    searchData.size = size
    return await axios.post(
        `${API_URL}/v1/order/list/calculated`,
        searchData,
        {headers: {'Authorization': `Bearer ${await auth.getAccessToken()}`}}
    )
}

function makeSearchData(searchData){
    searchData = Object.fromEntries(
        Object.entries(searchData).filter(([key, value]) => value !== null && value !== '' && key && value !== [])
    );
    if(typeof searchData.creation_date_from !== 'undefined'){
        searchData.creation_date_from = formatDate(searchData.creation_date_from)
    }
    if(typeof searchData.creation_date_to !== 'undefined'){
        searchData.creation_date_to = formatDate(searchData.creation_date_to)
    }
    return searchData
}
function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-11
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}