import {reactive} from 'vue'
import {getUser} from "@/api/user";
import {getCompanies} from "@/api/company";
import router from "@/router";
import {stores} from "@/data/store";

export const user = reactive({
    name: '',
    user_id: '',
    active_company: '',
    companies: [],

    async getUserBase() {
        await this.getUserData()
        return {
            name: this.name,
            user_id: this.user_id
        }
    },

    async getUserData() {
        if (!this.user_id) {
            this.user_id = localStorage.getItem('user_id')
        }
        if (!this.name) {
            const user_data = await getUser(this.user_id)
            this.name = user_data.data.name
        }
    },


    async getActiveCompany() {
        if (!this.active_company) {
            const storage_company = localStorage.getItem('active_company')
            if (storage_company) {
                this.setActiveCompany(storage_company, false)
            } else {
                const companies = await this.getUserCompanies()
                if (companies) {
                    this.setActiveCompany(companies[0].id)
                }
            }
        }
        return this.active_company
    },

    async getUserCompanies() {
        if (!this.companies.length) {
            const user_companies = await getCompanies()
            this.companies = user_companies.data
        }
        return this.companies
    },

    setActiveCompany(id, reset = true) {
        this.active_company = id
        localStorage.setItem('active_company', id)
        if (reset) {
            stores.resetStores()
        }
    },

    async afterLogout() {
        this.name = ''
        this.user_id = ''
        this.setActiveCompany('')
        this.companies = []
        await router.push({name: 'login'})
    }
})