export const ReportGroupOptions = [
    {code: 'day', name: 'День'},
    {code: 'week', name: 'Неделя'},
    {code: 'month', name: 'Месяц'},
    {code: 'year', name: 'Год'},
    {code: 'product', name: 'Товар'},
    {code: 'brand', name: 'Бренд'},
    {code: 'store', name: 'Магазин'},
    {code: 'marketplace', name: 'Маркетплейс'},
]

export const ReportStatuses = [
    {code: 'cancelled', name: 'Отменен'},
    {code: 'returned', name: 'Возврат'},
    {code: 'processing', name: 'Обрабатывается'},
    {code: 'delivery', name: 'Доставляется'},
    {code: 'delivered', name: 'Доставлен'},
    {code: 'unknown', name: 'Не определен'}
]

export const ReportOrderTypes = [
    {code: 'fbo', name: 'FBO'},
    {code: 'fbs', name: 'FBS'}
]
