<template>
  <BaseLayout>
    <div class="card card-action">
      <div class="card-header" v-if="WindowWidth <= 720" @click="mobileFilterShow = !mobileFilterShow">
        <h5 class="card-action-title mb-0">Фильтр по заказам</h5>
        <div class="card-action-element">
          <ul class="list-inline mb-0">
            <li class="list-inline-item">
              <i class="tf-icons ti scaleX-n1-rtl ti-sm"
                 :class="mobileFilterShow ? 'ti-chevron-down' : 'ti-chevron-right'"></i>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body" :class="{'collapse': WindowWidth <= 720, 'show': mobileFilterShow}">
        <form @submit.prevent="makeSearch">
          <div class="row gy-sm-1 mt-2">
            <div class="col-md-2 mt-2">
              <label class="form-label">Дата оформления от</label>
              <DatePicker v-model="searchData.creation_date_from" class="w-100"/>
            </div>
            <div class="col-md-2 mt-2">
              <label class="form-label">Дата оформления до</label>
              <DatePicker v-model="searchData.creation_date_to" class="w-100"/>
            </div>
            <div class="col-md-2 mt-2">
              <label class="form-label">Магазины</label>
              <MultiSelect v-model="searchData.stores" :options="stores" optionLabel="name" optionValue="id"
                           :showToggleAll="true"
                           placeholder="Все"
                           style="width: 100%">
                <template #option="slotProps">
                  <div class="d-flex align-content-center">
                    <img :src="slotProps.option.icon" alt="Маркетплейс" width="20px" style="margin-right: 3px">
                    <span>{{ slotProps.option.name }}</span>
                  </div>
                </template>
              </MultiSelect>
            </div>

            <div class="col-md-2 mt-2">
              <label class="form-label">Тип заказа</label>
              <MultiSelect v-model="searchData.types" :options="ReportOrderTypes" optionLabel="name" optionValue="code"
                           :showToggleAll="false"
                           placeholder="Все"
                           style="width: 100%"/>
            </div>

            <div class="col-md-2 mt-2">
              <label class="form-label">Статусы</label>
              <MultiSelect v-model="searchData.statuses" :options="ReportStatuses" optionLabel="name" optionValue="code"
                           :showToggleAll="true"
                           placeholder="Статус заказа"
                           style="width: 100%"/>
            </div>
            <div class="col-md-2 mt-2">
              <label for="group_by" class="form-label">Группировка</label>
              <Select v-model="searchData.group_by" :options="groupOptions" optionLabel="name" optionValue="code"
                      placeholder="Статус заказа"
                      style="width: 100%"/>
            </div>

          </div>

          <div class="row gy-sm-1">
            <div class="col-md-4 mt-3">
              <label class="form-label">Фильтр по товарам</label>
              <input type="text" name="name" class="form-control border-1 py-2" placeholder="Название/артикул"
                     v-model="searchData.name">
            </div>
          </div>
          <div class="row gy-sm-1">
            <div class="col-md-2 mt-4">
              <ButtonComponent ref="makeSearchButton" :class="'btn btn-primary'"
                               type="submit"
                               @click="makeSearch">Сформировать
              </ButtonComponent>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-if="reportTotals">
      <div class="row">
        <div class="col-xl-4 col-md-6 col-12 mt-6">
          <div class="card h-100">
            <LoadingBlock :loading="loadingReport">
              <div class="card-header pb-3">
                <h5 class="card-title mb-1">Сумма</h5>
                <p class="card-subtitle">оформленных заказов</p>
                <div class="d-flex justify-content-between align-items-center gap-3">
                  <h4 class="mb-0">{{ formatNumber(reportTotals['total_sum']) }} <small>{{ currency }}</small></h4>
                  <small class="text-success"></small>
                </div>
              </div>
            </LoadingBlock>
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-12 mt-6">
          <div class="card h-100">
            <LoadingBlock :loading="loadingReport">
              <div class="card-header pb-3">
                <h5 class="card-title mb-1">Количество</h5>
                <p class="card-subtitle">заказанных товаров</p>
                <div class="d-flex justify-content-between align-items-center gap-3">
                  <h4 class="mb-0">{{ formatNumber(reportTotals['products_count']) }} <small>шт</small></h4>
                  <small class="text-end">{{ formatNumber(reportTotals['average_price']) }} {{ currency }}<br>средний
                    чек</small>
                </div>
              </div>
            </LoadingBlock>
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-12 mt-6">
          <div class="card h-100">
            <LoadingBlock :loading="loadingReport">
              <div class="card-header pb-3">
                <h5 class="card-title mb-1">Себестоимость</h5>
                <p class="card-subtitle">заказанных товаров</p>
                <div class="d-flex justify-content-between align-items-center gap-3">
                  <h4 class="mb-0">{{ formatNumber(reportTotals['total_cost']) }} <small>{{ currency }}</small></h4>
                  <small class="text-success"></small>
                </div>
              </div>
            </LoadingBlock>
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-12 mt-6">
          <div class="card h-100">
            <LoadingBlock :loading="loadingReport">
              <div class="card-header pb-3">
                <h5 class="card-title mb-1">Комиссия</h5>
                <p class="card-subtitle">прогноз</p>
                <div class="d-flex justify-content-between align-items-center gap-3">
                  <h4 class="mb-0">{{ formatNumber(reportTotals['total_commission']) }} <small>{{ currency }}</small>
                  </h4>
                  <small class="text-success"></small>
                </div>
              </div>
            </LoadingBlock>
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-12 mt-6">
          <div class="card h-100">
            <LoadingBlock :loading="loadingReport">
              <div class="card-header pb-3">
                <h5 class="card-title mb-1">Налоги</h5>
                <p class="card-subtitle">предварительно</p>
                <div class="d-flex justify-content-between align-items-center gap-3">
                  <h4 class="mb-0">{{ formatNumber(reportTotals['total_tax']) }} <small>{{ currency }}</small></h4>
                  <small class="text-success"></small>
                </div>
              </div>
            </LoadingBlock>
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-12 mt-6">
          <div class="card h-100">
            <LoadingBlock :loading="loadingReport">
              <div class="card-header pb-3">
                <h5 class="card-title mb-1">Прибыль</h5>
                <p class="card-subtitle">прогноз</p>
                <div class="d-flex justify-content-between align-items-center gap-3">
                  <h4 class="mb-0">{{ formatNumber(reportTotals['total_profit']) }} <small>{{ currency }}</small><small
                      class="text-end"> ({{ formatNumber(reportTotals['profit_percent'], 2) }} %)</small></h4>
                </div>
              </div>
            </LoadingBlock>
          </div>
        </div>
      </div>
    </template>
    <div class="card card-fullscreen mt-6">
      <h5 class="card-header">Сводный отчет</h5>

      <div class="card-body">
        <LoadingBlock :loading="loadingReport">
          <div class="table-responsive text-nowrap">
            <table class="table table-bordered table-hover">
              <thead>
              <tr>
                <th>{{ getGroupName(searchData.group_by) }}</th>
                <th style="text-align: right">Заказано товаров</th>
                <th style="text-align: right">Средний чек</th>
                <th style="text-align: right">Сумма</th>
                <th style="text-align: right">Себестоимость</th>
                <th style="text-align: right">Комиссия</th>
                <th style="text-align: right">Налоги</th>
                <th style="text-align: right">Прибыль</th>
                <th style="text-align: right">Процент прибыли</th>
              </tr>
              </thead>
              <tbody>
              <template v-for="(row, index) in reportRows" :key="index">
                <tr>
                  <td>
                    {{ formatGroup(row.group) }}
                  </td>
                  <td style="text-align: right">
                    {{ row.products_count }}
                  </td>
                  <td style="text-align: right">
                    {{ formatNumber(row.average_price) }}
                  </td>
                  <td style="text-align: right">
                    {{ formatNumber(row.total_sum) }}
                  </td>
                  <td style="text-align: right">
                    {{ formatNumber(row.total_cost) }}
                  </td>
                  <td style="text-align: right">
                    {{ formatNumber(row.total_commission) }}
                  </td>
                  <td style="text-align: right">
                    {{ formatNumber(row.total_tax) }}
                  </td>
                  <td style="text-align: right">
                    {{ formatNumber(row.total_profit, 2) }}
                  </td>
                  <td style="text-align: right">
                    {{ formatNumber(row.profit_percent, 2) }}
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
        </LoadingBlock>
      </div>
    </div>


    <div class="card card-fullscreen mt-6">
      <h5 class="card-header">Заказы</h5>
      <div class="card-body">
        <LoadingBlock :loading="loadingOrders">
          <div class="table-responsive text-nowrap">
            <table class="table table-hover" v-if="WindowWidth > 720">
              <thead>
              <tr>
                <th>Детали</th>
                <th style="width: 300px;">Артикул, название</th>
                <th style="text-align: right">Сумма</th>
                <th style="text-align: right">Себестоимость</th>
                <th style="text-align: right">Комиссия</th>
                <th style="text-align: right">Налоги</th>
                <th style="text-align: right">Прибыль</th>
              </tr>
              </thead>
              <tbody>
              <template v-for="(row, index) in orders" :key="index">
                <tr>
                  <td>
                    {{ formatOrderDate(row.creation_date) }}<br>
                    <div class="flex-row d-flex align-content-center mb-2 mt-2">
                      <img :src="getMarketplaceIcon(row.marketplace)" width="20px" alt="Маркетплейс">
                      <div class=" badge rounded-pill" style="margin-left: 5px;"
                           :class="getStatusBadge(row.status)">
                        {{ getStatusName(row.status) }}
                      </div>
                    </div>
                    <small class="mt-1">{{ row.marketplace_number }}</small>
                  </td>
                  <td style="width: 300px; white-space: normal">
                    {{ row.product_code }}, {{ row.quantity }} шт<br>
                    <small>{{ row.product_current_name }}</small>
                  </td>
                  <td style="text-align: right" v-if="WindowWidth > 720">
                    {{ formatNumber(row.total_sum) }}
                  </td>
                  <td style="text-align: right" v-if="WindowWidth > 720">
                    {{ formatNumber(row.total_cost) }}
                  </td>
                  <td style="text-align: right" v-if="WindowWidth > 720">
                    {{ formatNumber(row.total_commission) }}
                  </td>
                  <td style="text-align: right" v-if="WindowWidth > 720">
                    {{ formatNumber(row.total_tax) }}
                  </td>
                  <td style="text-align: right" v-if="WindowWidth > 720">
                    <b>{{ formatNumber(row.total_profit) }} {{ currency }}</b><br>
                    <div class="ms-4 badge" :class="row.profit_percent > 0 ? 'bg-success' : 'bg-danger'">
                      {{ formatNumber(row.profit_percent, 1) }}%
                    </div>
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
          <div v-if="WindowWidth <= 720">
            <template v-for="(row, index) in orders" :key="index">
              <div class="w-100 mt-5" style="border: 1px solid #eee; border-radius: 10px; padding: 10px">

                {{ formatOrderDate(row.creation_date) }}
                <div class=" badge rounded-pill" style="margin-left: 5px;"
                     :class="getStatusBadge(row.status)">
                  {{ getStatusName(row.status) }}
                </div>
                <br>
                {{ row.product_code }}, {{ row.quantity }} шт<br>
                {{ row.product_current_name }}
                <table class="w-100 mt-2">
                  <tbody>
                  <tr>
                    <td>Сумма:</td>
                    <td style="text-align: right;">{{ formatNumber(row.total_sum) }} {{ currency }}</td>
                  </tr>
                  <tr>
                    <td>Себестоимость:</td>
                    <td style="text-align: right;">{{ formatNumber(row.total_cost) }} {{ currency }}</td>
                  </tr>
                  <tr>
                    <td>Комиссия:</td>
                    <td style="text-align: right;">{{ formatNumber(row.total_commission) }} {{ currency }}</td>
                  </tr>
                  <tr>
                    <td>Налоги:</td>
                    <td style="text-align: right;">{{ formatNumber(row.total_tax) }} {{ currency }}</td>
                  </tr>
                  <tr>
                    <td style="vertical-align: top">Прибыль:</td>
                    <td style="text-align: right;">{{ formatNumber(row.total_profit) }} {{ currency }}<br>
                      <div class="ms-4 badge" :class="row.profit_percent > 0 ? 'bg-success' : 'bg-danger'">
                        {{ formatNumber(row.profit_percent, 1) }}%
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <br>
                {{ formatOrderDate(row.creation_date) }}<br>
                <div class="flex-row d-flex align-content-center mb-2 mt-2">
                  <img :src="getMarketplaceIcon(row.marketplace)" width="20px" alt="Маркетплейс">
                  <small class="mt-1" style="margin-left: 5px">{{ row.marketplace_number }}</small>
                </div>
              </div>
            </template>
          </div>
          <BasePagination :info="paginationInfo" ref="productsPagination" @changePage="changePage">
          </BasePagination>
        </LoadingBlock>
      </div>
    </div>


  </BaseLayout>
</template>

<script>
import BaseLayout from '@/layouts/Base.vue'
import {PageInfo} from "@/data/page";
import {user} from "@/data/user";
import {getMarketplaceIcon, Marketplaces, stores} from "@/data/store";
import BasePagination from "@/components/list/Pagination.vue"

import {ReportGroupOptions, ReportOrderTypes, ReportStatuses} from "@/data/orders.js"

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import {getOrdersCalculated, getReportV1} from "@/api/order";
import LoadingBlock from "@/components/page/LoadingBlock.vue";
import MultiSelect from "primevue/multiselect";
import {Select} from "primevue";
import {DatePicker} from "primevue";
import ButtonComponent from "@/components/tools/Button.vue";
import {Message, Messages} from "@/data/messages";


export default {
  name: 'ReportV1View',
  computed: {
    ReportOrderTypes() {
      return ReportOrderTypes
    },
    ReportStatuses() {
      return ReportStatuses
    },
    WindowWidth() {
      return window.innerWidth
    }
  },
  // eslint-disable-next-line vue/no-unused-components
  components: {
    ButtonComponent,
    LoadingBlock,
    BaseLayout,
    BasePagination,
    MultiSelect,
    DatePicker,
    Select
  },
  data() {
    return {
      currency: 'р.',
      groupOptions: ReportGroupOptions,
      stores: [],
      paginationInfo: {
        page: 1,
        size: 50
      },
      searchData: {
        company: '',
        name: '',
        creation_date_from: new Date(),
        creation_date_to: new Date(),
        types: [],
        stores: [],
        statuses: ['processing', 'delivery', 'delivered'],
        marketplaces: [],
        group_by: 'day'
      },
      reportRows: [],
      reportTotals: {},
      orders: [],
      loadingReport: true,
      loadingOrders: true,
      mobileFilterShow: false
    }
  },
  methods: {
    getMarketplaceIcon,
    ReportGroupOptions() {
      return ReportGroupOptions
    },
    setPageInfo() {
      PageInfo.title = 'Отчет по заказам'
      PageInfo.breadcrumbs = [
        {
          title: 'Главная',
          link: '/'
        },
        {
          title: PageInfo.title
        }
      ]
    },
    setSearch() {
      this.searchData.company = user.active_company
    },
    async makeSearch() {
      this.loadingReport = true
      this.loadingOrders = true
      this.$refs.makeSearchButton.startLoading()
      await this.makeReport()
      this.loadingReport = false
      await this.searchOrders()
      this.loadingOrders = false
      this.$refs.makeSearchButton.endLoading()
    },
    async makeReport() {
      try {
        let result = await getReportV1(this.searchData)
        this.reportRows = result.data.rows
        this.reportTotals = result.data.totals
      } catch (error) {
        this.serverError()
      }
    },
    async searchOrders() {
      try {
        let result = await getOrdersCalculated(this.searchData, this.paginationInfo.page, this.paginationInfo.size)
        this.orders = result.data.items
        this.paginationInfo = {
          total: result.data.total,
          pages: result.data.pages,
          page: result.data.page,
          size: result.data.size
        }
      } catch (error) {
        this.serverError()
      }
    },
    serverError(text = '') {
      const message = new Message()
      if (!text.length) {
        text = 'Ошибка на стороне сервера, попробуйте позже или обратитесь в поддержку'
      }
      message.text = text
      message.type = 'failure'
      message.format = 'notification'
      Messages.list.push(message)
    },
    formatNumber(value, to_fixed = 0) {
      if (!value) return '0';
      value = value.toFixed(to_fixed)
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
    },
    formatGroup(group) {
      if (this.searchData.group_by === 'marketplace') {
        const marketplace = Marketplaces[group]
        if (typeof marketplace !== 'undefined') {
          return marketplace.name
        }
      }
      return group
    },
    getStatusName(status_code) {
      const status = ReportStatuses.find((status) => status.code === status_code)
      if (typeof status !== 'undefined') {
        return status.name
      }
      return status_code
    },
    async changePage(page) {
      this.paginationInfo.page = page
      await this.searchOrders()
    },
    getGroupName(group_code) {
      return ReportGroupOptions.find((group) => group.code === group_code).name
    },
    formatOrderDate(date_string) {
      const date = new Date(date_string);
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')
      return `${day}.${month}.${year} ${hours}:${minutes}`
    },
    getStatusBadge(status_code) {
      if (status_code === 'cancelled' || status_code === 'returned') {
        return 'bg-label-danger'
      }
      if (status_code === 'processing') {
        return 'bg-label-primary'
      }
      if (status_code === 'delivered') {
        return 'bg-label-success'
      }
      if (status_code === 'delivery') {
        return 'bg-label-warning'
      }
      return 'bg-label-info'
    }
  },
  async mounted() {
    this.setPageInfo()
    await user.getUserBase()
    this.setSearch()
    this.stores = await stores.getStores()
    await this.makeSearch()
  },
  watch: {
    '$route'() {
      this.setPageInfo()
      this.setSearch()
    }
  },
}
</script>