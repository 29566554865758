<template>
  <LoadingBlock :loading="storeLoading">
    <form @submit.prevent="wbSubmit">
      <div class="col mb-4">
        <label class="form-label">Название магазина</label>
        <input type="text" name="name" class="form-control" placeholder="" required v-model="item_data.name">
      </div>
      <input type="hidden" name="company" v-model="item_data.company">
      <input type="hidden" name="id" v-model="item_data.id" v-if="item_data.id">
      <div class="col mb-4">
        <label class="form-label">Токен</label>
        <input type="text" name="connection[token]" class="form-control" placeholder="" required
               v-model="item_data.connection.token">
      </div>
      <div class="col mb-4">
        <label class="form-label">Фиксированная комиссия (например, приемка в ПВЗ), руб.</label>
        <input type="text" name="settings[fixed_commission]" class="form-control" placeholder=""
               v-model="item_data.settings.fixed_commission">
      </div>
      <div class="col mb-4">
        <label class="form-label">Дополнительная комиссия (реклама, другие комиссии в процентах и т.п.), %</label>
        <input type="text" name="settings[percent_commission]" class="form-control" placeholder=""
               v-model="item_data.settings.percent_commission">
      </div>
      <div class="col mb-4">
        <label class="form-label">Коэффициент локализации (число от 0.5 до 3, зависит от индекса локализации,
          склада)</label>
        <input type="text" name="settings[local_coefficient]" class="form-control" placeholder=""
               v-model="item_data.settings.local_coefficient">
      </div>
      <div class="col mb-4">
        <button type="submit" class="btn btn-primary me-4 waves-effect waves-light">Сохранить</button>
      </div>
    </form>
  </LoadingBlock>
</template>
<script>
import {editStoreWb, newStoreWb} from "@/api/store";
import {user} from "@/data/user";
import LoadingBlock from "@/components/page/LoadingBlock.vue";
import {Message, Messages} from "@/data/messages";

export default {
  name: "WbForm",
  components: {LoadingBlock},
  data() {
    return {
      item_data: {
        id: '',
        name: '',
        company: '',
        marketplace: 'wb',
        connection: {
          token: '',
        },
        settings: {
          model: 'fbs',
          fixed_commission: 30,
          percent_commission: 0,
          local_coefficient: 1
        }
      },
      storeLoading: true
    }
  },
  props: ['item'],
  mounted() {
    if (this.item) {
      this.item_data = this.item
    }
    this.storeLoading = false
  },
  methods: {
    async wbSubmit() {
      this.storeLoading = true
      let result
      let text = 'Магазин успешно создан'
      if (!this.item_data.id) {
        this.item_data.company = await user.getActiveCompany()
        result = await newStoreWb(this.item_data)
      } else {
        result = await editStoreWb(this.item_data)
        text = 'Настройки магазина изменены'
      }
      const message = new Message()
      if (result.status === 200 || result.status === 201) {
        message.type = 'success'
        message.autoremove = 10
        message.text = text
        this.$emit('storeSuccess')
      } else {
        message.text = 'Ошибка на стороне сервера, попробуйте попытку или обратитесь в поддержку.'
        message.type = 'failure'
      }
      Messages.list.push(message)
      this.storeLoading = false
    }
  }
}
</script>