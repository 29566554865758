export function getChangeInfo(e) {
    const change = {
        what_to_change: '',
        info: {}

    }
    if (e.colDef.field === 'name' || e.colDef.field === 'code') {
        alert('Редактировать товар можно только в разделе "товары"')
        return false
    }
    let difference = 0
    if (typeof e.oldValue === 'number' && e.oldValue !== 0) {
        difference = Math.abs(e.newValue - e.oldValue) / e.oldValue;
    }
    if (e.colDef.field === 'min_price') {
        change.what_to_change = 'min_price'
        change.info = {
            data: {
                'code': e.data.context.code,
                'min_price': e.newValue,
            },
            product_id: e.data.id,
            difference: difference
        }
        return change
    }
    if (e.colDef.field === 'cost') {
        change.what_to_change = 'cost'
        change.info = {
            data: {
                'code': e.data.context.code,
                'cost': e.newValue
            },
            product_id: e.data.id,
            'difference': difference
        }
        return change
    }
    if (e.colDef.field.includes('_profit_amount')) {
        const store_id = e.colDef.field.replace('_profit_amount', '')
        const offer_id = e.data[store_id].offer_id
        change.what_to_change = 'profit'
        change.info = {
            'offer_id': offer_id,
            'profit_amount': e.newValue,
            'difference': difference
        }
        return change
    }
    if (e.colDef.field.includes('_price')) {
        const store_id = e.colDef.field.replace('_price', '')
        const offer_id = e.data[store_id].offer_id
        change.what_to_change = 'price'
        change.info = {
            'offer_id': offer_id,
            'price': e.newValue,
            'difference': difference
        }
        return change
    }
    if (e.colDef.field.includes('_profit_percent')) {
        const store_id = e.colDef.field.replace('_profit_percent', '')
        const offer_id = e.data[store_id].offer_id
        change.what_to_change = 'profit_percent'
        change.info = {
            'offer_id': offer_id,
            'profit_percent': e.newValue,
            'difference': difference
        }
        return change
    }
    return change
}

export function isEditable(cell) {
    if (cell.data.in_process) {
        return false
    }
    const store_id = cell.colDef.context.store_id
    if (cell.colDef.context.marketplace === 'ozon') {
        if (cell.data[store_id].store_info['is_in_actions']) {
            alert('Товар участвует в акции. Отредактируйте цену на Озоне или уберите товар из акции и обновите информацию в системе.')
            return false
        }
    }
    return true
}