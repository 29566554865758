<template>
  <aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme" :class="MobileMenu.open?'active':''">
    <div class="app-brand">
      <img src="@/assets/logo.svg" alt="Profit">

      <a href="javascript:void(0);" class="layout-menu-toggle menu-link text-large ms-auto" @click.prevent="MobileMenu.toggle()">
        <i class="ti ti-x d-block d-xl-none ti-md align-middle"></i>
      </a>
    </div>

    <div class="menu-inner-shadow"></div>

    <ul class="menu-inner py-1" v-if="menu.items">
      <li class="menu-item" v-for="(item, index) in menu.items" :key="index" :class="item.active?'active':''">
        <RouterLink :to="item.to" v-if="item.to" class="menu-link">
          <i class="menu-icon tf-icons ti" :class="item.icon"></i>
          <div>{{item.title}}</div>
        </RouterLink>
      </li>
    </ul>
  </aside>
</template>

<script>

import {menu, MobileMenu} from "@/data/menu";

export default {
  name: "MainMenu",
  data() {
    return {
      mobileActive: false
    }
  },
  computed: {
    MobileMenu() {
      return MobileMenu
    },
    menu() {
      return menu
    },

  },
  methods: {
    toggleMenu() {
      MobileMenu.open = false
    }
  }
}
</script>

<style scoped>
.layout-menu{
  transition: all 0.5s;
}
.layout-menu.active{
  transform: translate3d(0, 0, 0) !important;
}
.layout-navbar-fixed body:not(.modal-open) .layout-content-navbar .layout-menu, .layout-menu-fixed body:not(.modal-open) .layout-content-navbar .layout-menu{
  z-index: 900;
}
.app-brand img{
  height: 50px;
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>