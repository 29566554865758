<template>
  <template v-for="(item, index) in messagesList" :key="index">
    <BaseModal :modal-title="item.title" :opened="true" v-if="item.format === 'modal'">
      {{ item.text }}
    </BaseModal>
  </template>
  <div class="notifications-top ">
    <TransitionGroup name="list" tag="div">
      <template v-for="(item, index) in messagesList" :key="index">
        <div class="alert alert-dismissible d-flex notification-alert" role="alert"
             :class="{'alert-success': item.type === 'success','alert-primary': item.type === 'warning','alert-danger': item.type === 'failure'}"
             v-if="item.format === 'notification'">
                        <span class="alert-icon rounded" >
                          <i class="ti ti-ban" v-if="item.type === 'failure'"></i>
                          <i class="ti ti-check" v-if="item.type === 'success'"></i>
                          <i class="ti ti-info-circle" v-if="item.type === 'warning'"></i>
                        </span>
          <div class="d-flex flex-column ps-1">
            <h5 class="alert-heading mb-2">{{item.title}}</h5>
            <p class="mb-0">
              {{item.text}}
            </p>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"
                    @click="removeMessage(item.id)"></button>
          </div>
        </div>
      </template>
    </TransitionGroup>
  </div>
</template>

<script>
import {Messages} from "@/data/messages";
import BaseModal from "@/components/Modal.vue";

export default {
  name: "MessagesComponent",
  components: {BaseModal},
  methods: {
    removeMessage(id) {
      Messages.list = Messages.list.filter(item => item.id !== id);
    },
    checkAutoRemove() {
      Messages.list.forEach((item) => {
        if (item.autoremove > 0) {
          setTimeout(() => {
            this.removeMessage(item.id)
          }, item.autoremove * 1000)
        }
      })
    }
  },
  computed: {
    messagesList() {
      return Messages.list
    }
  },
  watch: {
    messagesList: {
      handler() {
        this.checkAutoRemove()
      },
      deep: true
    },
  }
}
</script>
<style>
.notifications-top {
  position: fixed;
  height: auto;
  top: 0;
  right: 0;
  padding-top: 30px;
  padding-right: 30px;
  display: flex;
  align-items: flex-end;
  z-index: 1500;
  flex-direction: column;
}

.notification-alert {
  max-width: 400px;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
}
</style>