<template>
  <BaseLayout>
    <template v-slot:top_buttons>
      <div class="demo-inline-spacing">
        <button type="button" class="btn btn-outline-secondary waves-effect" @click="importMinPrices">
          Импорт РРЦ
        </button>
        <button type="button" class="btn btn-outline-secondary waves-effect" @click="importCosts">
          Импорт себестоимоси
        </button>
        <button type="button" class="btn btn-primary" @click="createProduct">
          Новый товар
        </button>
      </div>
    </template>

    <BaseModal ref="productModal" :modal-title="modalTitle">
      <ProductForm ref="productForm" @product-success="productSuccess">

      </ProductForm>
    </BaseModal>

    <BaseModal ref="importModal" :modal-title="importModalTitle">
      <ProductImportFileForm ref="ProductImportFileForm" @file-success="fileSuccess">

      </ProductImportFileForm>
    </BaseModal>

    <div class="card">
      <div class="card-body">
        <div class="row gy-sm-1">
          <div class="col-md-3">
            <div class="input-group input-group-merge shadow-none">
              <input type="text" class="form-control border-1 py-2" placeholder="Поиск по названию"
                     v-model="searchData.name" @change="search">
            </div>
          </div>
          <div class="col-md-3">
            <div class="input-group input-group-merge shadow-none">
              <input type="text" class="form-control border-1 py-2" placeholder="Поиск по бренду"
                     v-model="searchData.brand" @change="search">
            </div>
          </div>
        </div>
        <div v-if="!products.length && !productsLoading" class="mt-7">
          Товары не найдены
        </div>
      </div>
      <LoadingBlock :loading="productsLoading">
        <div class="table-responsive text-nowrap">
          <table class="table">
            <tbody class="">
            <tr v-for="(product, index) in products" :key="index">
              <td><a href="#" @click.prevent="editProduct(product)">{{ product.name }}</a></td>
              <td style="text-align: right">
                <button type="button" class="btn btn-icon btn-label-primary waves-effect d-inline-block m-1"
                        @click="editProduct(product)">
                  <span class="ti ti-edit ti-md"></span>
                </button>
                <button type="button" class="btn btn-icon btn-label-secondary waves-effect d-inline-block"
                        @click="deleteProduct(product.id)">
                  <span class="ti ti-trash ti-md"></span>
                </button>
              </td>
            </tr>
            </tbody>
          </table>

          <BasePagination :info="paginationInfo" ref="productsPagination" @changePage="changePage">
          </BasePagination>
        </div>
      </LoadingBlock>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/layouts/Base.vue'
import {PageInfo} from "@/data/page";
import {user} from "@/data/user";
import {deleteProduct, getProducts} from "@/api/product";
import BaseModal from "@/components/Modal.vue";
import ProductForm from "@/components/forms/Product.vue";
import BasePagination from "@/components/list/Pagination.vue"
import ProductImportFileForm from "@/components/forms/ProductImportFiles.vue";
import LoadingBlock from "@/components/page/LoadingBlock.vue";

export default {
  name: 'ProductsView',
  components: {LoadingBlock, ProductImportFileForm, ProductForm, BaseModal, BaseLayout, BasePagination},
  data() {
    return {
      products: [],
      searchResultReady: false,
      productsLoading: true,
      modalTitle: 'Новый товар',
      importModalTitle: 'Загрузка себестоимости',
      paginationInfo: {},
      searchData: {
        name: '',
        brand: ''
      }
    }
  },
  methods: {
    async getProducts() {
      this.productsLoading = true
      const result = await getProducts(user.active_company, this.$route.query)
      this.paginationInfo = {
        total: result.data.total,
        pages: result.data.pages,
        page: result.data.page,
        size: result.data.size
      }
      this.products = result.data.items
      this.productsLoading = false
    },
    async search() {
      const query = {...this.$route.query}
      delete query.page
      Object.entries(this.searchData).forEach(([key, value]) => {
        if (value) {
          query[key] = value
        } else {
          delete query[key]
        }
      });
      console.log(
          this.$route
      )
      await this.$router.push({
        path: this.$route.path,
        query: {
          ...query
        }
      })

    },
    setSearch() {
      if (this.$route.query.name) {
        this.searchData.name = this.$route.query.name
      } else {
        this.searchData.name = ''
      }
      if (this.$route.query.brand) {
        this.searchData.brand = this.$route.query.brand
      } else {
        this.searchData.brand = ''
      }
    },
    async createProduct() {
      this.modalTitle = 'Новый товар'
      setTimeout(() => {
        this.$refs.productModal.openModal()
        this.$refs.productForm.updateItem({})
      }, 100)
    },
    async editProduct(item) {
      this.modalTitle = 'Рекдактировать товар'
      setTimeout(() => {
        this.$refs.productModal.openModal()
        this.$refs.productForm.updateItem(item)
      }, 100)
    },
    async deleteProduct(product_id) {
      if (confirm('Вы уверены, что хотите удалить товар?')) {
        await deleteProduct(product_id)
        await this.getProducts()
      }
    },
    async changePage(page) {
      await this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: page
        }
      });
    },
    setPageInfo() {
      PageInfo.title = 'Товары'
      PageInfo.breadcrumbs = [
        {
          title: 'Главная',
          link: '/'
        },
        {
          title: PageInfo.title
        }
      ]
    },
    importMinPrices() {
      this.importModalTitle = "Загрузка РРЦ"
      this.$refs.importModal.openModal()
      this.$refs.ProductImportFileForm.setImport('minPrices')
    },
    importCosts() {
      this.importModalTitle = "Загрузка себестоимости"
      this.$refs.importModal.openModal()
      this.$refs.ProductImportFileForm.setImport('costs')
    },
    fileSuccess(){
      this.$refs.importModal.closeModal()
    },
    async productSuccess(){
      this.$refs.productModal.closeModal()
      await this.getProducts()
    }
  },
  async mounted() {
    this.setPageInfo()
    this.setSearch()
    await user.getUserBase()
    await this.getProducts()
  },
  watch: {
    '$route'() {
      this.setSearch()
      this.setPageInfo()
      this.getProducts()
    }
  }
}
</script>