import { reactive } from 'vue'

export const price = reactive({
    settings: {},
    defaultSettings: {
        columns: ['cost', 'price', 'profit', 'profit_percent', 'commission'],
        orderType: 'fbs'
    },

    getSettings(){
        if(this.settings.length){
            return this.settings
        }
        if (localStorage.getItem('price_settings')){
            this.settings = JSON.parse(localStorage.getItem('price_settings'))
            return this.settings
        }
        return this.defaultSettings
    },
    setSettings(settings){
        this.settings = settings
        localStorage.setItem('price_settings', JSON.stringify(settings))
    }

})