import {reactive} from 'vue'
import {getStores} from "@/api/store";
import {user} from "@/data/user";

export const stores = reactive({
    stores: [],
    activeStores: [],

    async getStores() {
        if (this.stores.length) {
            return this.stores
        }
        await this.updateStores()
        return this.stores
    },
    async updateStores() {
        const active_company = await user.getActiveCompany()
        const result = await getStores(active_company)
        this.stores = result.data.items
        this.stores.forEach((item) => item.icon = getMarketplaceIcon(item.marketplace))
    },
    async getActiveStores() {
        if (!this.activeStores.length && localStorage.getItem('active_stores')) {
            const storage_stores = JSON.parse(localStorage.getItem('active_stores'))
            const active_company = await user.getActiveCompany()
            if (typeof storage_stores[active_company] !== "undefined") {
                this.activeStores = storage_stores[active_company]
            }
        }
        let all_stores = await this.getStores()
        all_stores = all_stores.map(store => store.id)
        this.activeStores = this.activeStores.filter(store => all_stores.includes(store))
        return this.activeStores
    },
    async setActiveStores(stores) {
        this.activeStores = stores
        const active_company = await user.getActiveCompany()
        let storage_stores = JSON.parse(localStorage.getItem('active_stores'))
        if (!storage_stores || storage_stores.constructor !== Object) {
            storage_stores = {}
        }
        storage_stores[active_company] = stores
        localStorage.setItem('active_stores', JSON.stringify(storage_stores))
    },
    resetStores() {
        this.stores = []
        this.activeStores = []
    }

})

export const Marketplaces = {
    yandex: {
        name: 'Яндекс Маркет',
        icon: require('@/assets/images/marketplaces/yandex.svg'),
        code: 'yandex'
    },
    wb: {
        name: 'Wildberries',
        icon: require('@/assets/images/marketplaces/wb.svg'),
        code: 'wb'
    },
    ozon: {
        name: 'Озон',
        icon: require('@/assets/images/marketplaces/ozon.svg'),
        code: 'ozon'
    }
}

export function getMarketplaceIcon(marketplace_code)
{
    const marketplace = Marketplaces[marketplace_code]
    if (typeof marketplace !== 'undefined') {
        return marketplace.icon
    }
    return ''
}