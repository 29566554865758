<template>
  <div class="popover bs-popover-auto fade show">
    <div class="popover-body">
      <template v-if="commissions">
        <p><b>Информация о комиссии</b></p>
        <table class="table table-flush-spacing">
          <tbody>
          <tr v-for="(commission, index) in commissions.percents" :key="index">
            <td>{{ commission.name }} {{ formatNumber(commission.percent, 1) }}%</td>
            <td class="align-right" style="white-space: nowrap">{{ formatNumber(commission.amount) }} {{ currency }}</td>
          </tr>
          <tr v-for="(commission, index) in commissions.percents_with_limit" :key="index">
            <td>{{ commission.name }} {{ formatNumber(commission.percent, 1) }}%</td>
            <td class="align-right" style="white-space: nowrap">{{ formatNumber(commission.amount) }} {{ currency }}</td>
          </tr>
          <tr v-for="(commission, index) in commissions.fixed" :key="index">
            <td>{{ commission.name }}</td>
            <td class="align-right" style="white-space: nowrap">{{ formatNumber(commission.amount) }} {{ currency }}</td>
          </tr>
          </tbody>
        </table>
      </template>
      <template v-if="ozonInfo.marketing_price || ozonInfo.marketing_seller_price">
        <p class="mt-4"><b>Информация от Озона</b></p>
        <table class="table table-flush-spacing">
          <tbody>
          <tr v-if="ozonInfo.marketing_price">
            <td>Цена на Озоне со скидкой</td>
            <td>{{ ozonInfo.marketing_price }} {{ currency }}</td>
          </tr>
          <tr v-if="ozonInfo.marketing_seller_price">
            <td>Цена продавца с учетом всех акций</td>
            <td>{{ ozonInfo.marketing_seller_price }} {{ currency }}</td>
          </tr>
          <tr v-if="ozonInfo.min_ozon_price">
            <td>Минимальная цена на Озоне</td>
            <td>{{ ozonInfo.min_ozon_price }} {{ currency }}</td>
          </tr>
          </tbody>
        </table>
      </template>

      <p v-if="url" class="mt-3"><a :href="url" target="_blank" @click="hideTooltipCallback"><b>Перейти на карточку</b></a></p>

    </div>
  </div>
</template>

<script>
export default {
  name: 'MarketplaceTooltip',
  data() {
    return {
      url: '',
      commissions: {
        percents: [],
        fixed: [],
        percents_with_limit: []
      },
      store_info: [],
      currency: 'Р',
      ozonInfo: {}
    }
  },
  methods: {
    hideTooltipCallback() {
      if (this.params.hideTooltipCallback) {
        this.params.hideTooltipCallback();
      }
    },
    formatNumber(value, to_fixed = 0) {
      if (!value) return '0';
      value = value.toFixed(to_fixed)
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
    },
  },
  beforeMount() {
    const cell = this.params.value
    const store_id = cell.colDef.context.store_id
    const marketplace = cell.colDef.context.marketplace
    if (marketplace === 'ozon') {
      this.ozonInfo = {
        marketing_price: cell.data[store_id].store_info['marketing_price'],
        marketing_seller_price: cell.data[store_id].store_info['marketing_seller_price'],
        min_ozon_price: cell.data[store_id].store_info['min_ozon_price'],
      }
    }
    this.url = cell.data[store_id].store_url
    this.commissions = cell.data[store_id].profit_info['commission']
  },
}
</script>

<style>
.popover {
  max-width: 400px;
  --bs-popover-max-width: 400px;
}

.align-right {
  text-align: right;
}
</style>