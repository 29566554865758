import axios from "axios";
import {API_URL} from "@/api/base";
import {auth} from "@/data/auth";


export async function getCompanies(){
    return await axios.get(
        `${API_URL}/v1/company/user_companies`,
        {headers: {'Authorization': `Bearer ${await auth.getAccessToken()}`}}
    )
}