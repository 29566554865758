<template>
  <div class="loading-block">
    <div v-if="loading" class="page-load-out">
      <div class="spinner-grow text-info" role="status">
        <span class="visually-hidden">Загрузка...</span>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'LoadingBlock',
  computed: {},
  props: {
    loading: {
      type: Boolean,
      default: true
    }
  }
}
</script>
<style scoped>
.page-load-out {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  position: absolute;
  z-index: 999;
  left: 0;
  right: 0;
}
.loading-block{
  position: relative;
  min-height: 100px;
}
</style>
