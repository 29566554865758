<template>
  <!-- Layout wrapper -->
  <div class="layout-wrapper layout-content-navbar">
    <div class="layout-container">
      <!-- Menu -->
      <MainMenu></MainMenu>
      <!-- / Menu -->

      <!-- Layout container -->
      <div class="layout-page">
        <!-- Navbar -->
        <NavBar></NavBar>
        <!-- / Navbar -->

        <!-- Content wrapper -->
        <div class="content-wrapper">
          <!-- Content -->

          <div class="container-xxl flex-grow-1 container-p-y">
            <div v-if="PageInfo.title"
                 class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-6 row-gap-4">
              <div class="d-flex flex-column justify-content-center">
                <h4 class="mb-1">{{ PageInfo.title }}</h4>
                <p class="mb-0" v-if="PageInfo.breadcrumbs.length">
                  <BreadCrumb></BreadCrumb>
                </p>
              </div>
              <slot name="top_buttons"></slot>
            </div>
            <slot></slot>
          </div>
          <!-- / Content -->

          <!-- Footer -->
          <BaseFooter></BaseFooter>
          <!-- / Footer -->


          <!-- Messages -->
          <MessagesComponent ref="BaseMessages"></MessagesComponent>
          <!-- / Messages -->

          <div class="content-backdrop fade"></div>
        </div>
        <!-- Content wrapper -->
      </div>
      <!-- / Layout page -->
    </div>

    <!-- Overlay -->
    <div class="layout-overlay layout-menu-toggle" :class="MobileMenu.open ? 'active' : ''"
         @click="MobileMenu.toggle()"></div>

    <!-- Drag Target Area To SlideIn Menu On Small Screens -->
    <div class="drag-target"></div>
  </div>
  <!-- / Layout wrapper -->
</template>

<script>

import MainMenu from "@/components/page/Menu.vue";
import NavBar from "@/components/page/NavBar.vue";
import BaseFooter from "@/components/page/Footer.vue";
import {MobileMenu} from "@/data/menu";
import {PageInfo} from "@/data/page";
import BreadCrumb from "@/components/page/Breadcrumb.vue";
import MessagesComponent from "@/components/page/Messages.vue";

export default {
  name: 'BaseLayout',
  computed: {
    PageInfo() {
      return PageInfo
    },
    MobileMenu() {
      return MobileMenu
    }
  },
  components: {MessagesComponent, BreadCrumb, BaseFooter, NavBar, MainMenu},
}
</script>
<style scoped>
.layout-overlay.active {
  display: block;
}

.layout-overlay {
  transition: all 0.5s;
  z-index: 800;
}


@media (max-width: 1200px) {
  .layout-overlay {
    z-index: 800;
  }
}
</style>

