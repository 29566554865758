<template>
  <a :href="url" target="_blank"><i class="tf-icons ti ti-link"></i></a>
</template>

<script>
export default {
  name: 'StoreLink',
  data() {
    return {
      url: ''
    }
  },
  methods: {

  },
  beforeMount() {
    this.url = this.params.value;
  },
}
</script>