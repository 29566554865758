<template>
  <div class="offcanvas-backdrop fade" :class="show ? 'show' : ''" @click="close"></div>
  <div
      class="offcanvas offcanvas-end" :class="show ? 'show' : ''"
      tabindex="-1"
      id="offcanvasEnd"
      aria-labelledby="offcanvasEndLabel">
    <div class="offcanvas-header">
      <h5 id="offcanvasEndLabel" class="offcanvas-title">{{ title }}</h5>
      <button
          @click="close"
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Закрыть"></button>
    </div>
    <div class="offcanvas-body">
     <slot></slot>
    </div>
  </div>
</template>
<script>
import {VOnClickOutside as ClickOutside} from "@/helpers/directives/clickOutside"

export default {
  name: "OffCanvas",
  data() {
    return {
      show: false
    }
  },
  props: {
    title: {
      type: String
    },
    opened: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close() {
      if (this.show) {
        this.show = false
      }
    },
    open() {
      setTimeout(() => this.show = true, 100)
    }
  },
  directives: {
    ClickOutside
  },
  mounted() {
    if (this.opened) {
      this.open()
    }
  }
}
</script>
<style scoped>
.offcanvas-backdrop {
  display: none;
  z-index: 999;
}

.offcanvas-backdrop.show {
  display: block;
}
.offcanvas{
  z-index: 1000;
}
</style>