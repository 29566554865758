<template>
  <BaseLayout>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/layouts/Base.vue";
import {stores} from "@/data/store";

export default {
  name: 'HomeView',
  components: {BaseLayout},
  data() {
    return {
      user_name: ''
    }
  },
  methods: {

  },
  async beforeMount() {
    const user_stores = await stores.getStores()
    if(user_stores.length){
      this.$router.push('prices')
    }else{
      this.$router.push('stores')
    }
  }
}
</script>