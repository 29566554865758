<template>
  <LoadingBlock :loading="productLoading">
    <form @submit.prevent="productSubmit">
      <input type="hidden" name="id" class="form-control" placeholder="" v-model="item_data.id">
      <div class="col mb-4">
        <label class="form-label">Название товара</label>
        <input type="text" name="name" class="form-control" placeholder="" required v-model="item_data.name">
      </div>
      <input type="hidden" name="company" class="form-control" placeholder="" required v-model="item_data.company">
      <div class="col mb-4">
        <label class="form-label">Внутренний код товара (артикул продавца)</label>
        <input type="text" name="code" class="form-control" placeholder="" required v-model="item_data.code">
      </div>
      <div class="col mb-4">
        <label class="form-label">Себестоимость</label>
        <input type="text" name="cost" class="form-control" placeholder="" v-model="item_data.cost">
      </div>
      <div class="col mb-4">
        <label class="form-label">Минимальная цена (РРЦ)</label>
        <input type="text" name="min_price" class="form-control" placeholder="" v-model="item_data.min_price">
      </div>
      <div class="col mb-4">
        <label class="form-label">Бренд</label>
        <input type="text" name="brand" class="form-control" placeholder="" v-model="item_data.brand">
      </div>
      <div class="col mb-4">
        <label class="form-label">Ширина (мм)</label>
        <input type="text" name="width_mm" class="form-control" placeholder="" v-model="item_data.width_mm">
      </div>
      <div class="col mb-4">
        <label class="form-label">Высота (мм)</label>
        <input type="text" name="height_mmm" class="form-control" placeholder="" v-model="item_data.height_mm">
      </div>
      <div class="col mb-4">
        <label class="form-label">Длина (мм)</label>
        <input type="text" name="length_mm" class="form-control" placeholder="" v-model="item_data.length_mm">
      </div>
      <div class="col mb-4">
        <label class="form-label">Вес (г)</label>
        <input type="text" name="weight_gr" class="form-control" placeholder="" v-model="item_data.weight_gr">
      </div>
      <div class="col mb-4 text-center">
        <button type="submit" class="btn btn-primary me-4 waves-effect waves-light">Сохранить</button>
      </div>
    </form>
  </LoadingBlock>
</template>
<script>
import {user} from "@/data/user";
import {newProduct, updateProduct} from "@/api/product";
import LoadingBlock from "@/components/page/LoadingBlock.vue";
import {Message, Messages} from "@/data/messages";


export default {
  name: "ProductForm",
  components: {LoadingBlock},
  data() {
    return {
      item_data: {
        id: '',
        company: '',
        code: '',
        name: '',
        brand: '',
        width_mm: '',
        height_mm: '',
        length_mm: '',
        weight_gr: '',
        cost: '',
        min_price: ''
      },
      productLoading: false,
    }
  },
  methods: {
    async productSubmit() {
      let text
      let result
      this.productLoading = true
      if (!this.item_data.id) {
        this.item_data.company = await user.getActiveCompany()
        result = await newProduct(this.item_data)
        text = 'Товар успешно создан'
      } else {
        result = await updateProduct(this.item_data.id, this.item_data)
        text = 'Данные товара обновлены'
      }
      const message = new Message()
      if (result.status === 200 || result.status === 201) {
        message.type = 'success'
        message.autoremove = 10
        message.text = text
        this.$emit('productSuccess')
      } else {
        message.type = 'failure'
        message.text = 'При создании/обновлении товара ошибка. Проверьте данные, попробуйте снова или обратитесь в поддержку.'
      }
      Messages.list.push(message)
      this.productLoading = false
    },
    updateItem(item) {
      this.item_data = item
    }
  }
}
</script>